/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from '../../atoms/Button/Button';
import ProductBrand from '../../atoms/ProductBrand/ProductBrand';
import ProductName from '../../atoms/ProductName/ProductName';
import ProductNormalPrice from '../../atoms/ProductNormalPrice/ProductNormalPrice';
import ProductPrice from '../../atoms/ProductPrice/ProductPrice';
import RatingStars from '../../molecules/RatingStars/RatingStars';
import style from './ProductCardVertical.module.scss';
import Timer from '../../molecules/Timer/Timer';
import ProductImageVertical from '../../molecules/ProductImageVertical/ProductImageVertical';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import Badge from '../../atoms/Badge/Badge';
import DiscountBadge from '../../atoms/DiscountBadge/DiscountBadge';
import IconButton from '../../atoms/IconButton/IconButton';
import { ReactComponent as AddCar } from '../../../../assets/svg/AddCar.svg';
import { ReactComponent as CmrBadge } from '../../../../assets/svg/CmrBadge.svg';
import { ReactComponent as InternetPrice } from '../../../../assets/svg/InternetPrice.svg';
import { ReactComponent as Success } from '../../../../assets/svg/Success.svg';
import BadgesContainer from '../BadgesContainer/BadgesContainer';
import Loader from '../../atoms/Loader/Loader';
import Typography from '../../atoms/Typography/Typography';

const ProductCardVertical = ({
  name,
  onClick,
  onCheckboxClick,
  isAddToCartLoading,
  onClickSaveList,
  ...props
}) => {
  const [selectedVariant, setSelectedVariant] = useState(
    props?.variants[0]?.options?.find((option) => option.selected)
      || props?.variants[0]?.options[0],
  );

  useEffect(() => {
    const initialVariant = props?.variants[0]?.options.find((option) => option.selected)
      || props?.variants[0]?.options[0]
      || null;
    setSelectedVariant(initialVariant);
  }, [props?.variants[0]?.options]);

  const renderVariants = (variants) => (
    <div className={style.variant}>
      {variants?.map((option) => (
        <button
          key={option.value}
          type="button"
          className={`${style.variant__button} ${
            selectedVariant?.value === option.value
              ? style.variant__button_selected
              : ''
          }`}
          onClick={() => setSelectedVariant(option)}
        >
          <Typography variant="kappa14">{option.label}</Typography>
        </button>
      ))}
    </div>
  );

  const oldPrice = props.prices[0].price !== props.prices[props.prices.length - 1].price
    && props.prices[props.prices.length - 1].price;

  const oldPriceVariant = selectedVariant?.prices[0]?.price
      !== selectedVariant?.prices[selectedVariant?.prices?.length - 1]?.price
    && selectedVariant?.prices[selectedVariant?.prices?.length - 1]?.price;

  return (
    <BadgesContainer
      bankBadge={props.bankBadge}
      eventBadge={props.events}
      garantieBadge={props.garantieBadge}
      discountBadge={props.badges}
      multiPurposeIcon={props.multiPurposeIcon}
    >
      {({
        hasBankBadge,
        hasGarantieBadge,
        hasDiscountBadge,
        hasEventBadge,
        hasMultiPurposeIcon,
      }) => (
        <div className={classNames(style.productVertical)}>
          <div className={style.productVertical__container}>
            <section>
              <div className={style.productVertical__header}>
                <Checkbox
                  className={style.productVertical__compare}
                  label="Comparar"
                  name={props.skuId}
                  checked={props.isChecked}
                  onHandleChange={onCheckboxClick}
                />
                <Badge
                  state={hasEventBadge}
                  className={style.productVertical__eventBadge}
                  name={props.events[0]?.description}
                  url={props.events[0]?.value}
                />
              </div>
              <ProductImageVertical
                className={style.productVertical__image}
                name={props.name}
                sku={props.skuId}
                onClick={() => onClick(
                  selectedVariant
                    ? selectedVariant?.targetId
                    : props.productId,
                )}
                badges={(
                  <>
                    <Badge
                      state={hasBankBadge}
                      className={style.productVertical__bankBadge}
                      name={props.bankBadge?.description}
                      url={props.bankBadge?.value}
                    />
                    <Badge
                      state={hasGarantieBadge}
                      className={style.productVertical__garantieBadge}
                      name={props.garantieBadge?.description}
                      url={props.garantieBadge?.value}
                    />
                    <DiscountBadge
                      state={hasDiscountBadge}
                      className={style.productVertical__discountBadge}
                      label={props.badges[0]?.value}
                    />
                  </>
                )}
              />

              <ProductBrand content={props.brand} />
              <ProductName
                content={selectedVariant ? selectedVariant?.name : name}
              />
              <RatingStars
                className={style.productVertical__ratingStars}
                color="#006AC1"
                rating={Math.floor(parseFloat(props.rating))}
                name="myRating"
                totalReviews={props.totalReviews}
                showReviews
              />

              {renderVariants(props?.variants[0]?.options)}
            </section>

            <section>
              {/* Badge Mobile */}
              <div className={style.productVertical__BadgeMobile}>
                <section className={style.productVertical__BadgeMobile__price}>
                  <div>
                    <div className={style.productVertical__priceBadge}>
                      {selectedVariant
                        ? selectedVariant.prices[0]?.type === 'CMR' && (
                        <CmrBadge />
                        )
                        : props.prices[0]?.type === 'CMR' && <CmrBadge />}
                      {selectedVariant
                        ? selectedVariant.prices[0]?.type === 'INTERNET' && (
                        <InternetPrice />
                        )
                        : props.prices[0]?.type === 'INTERNET' && (
                        <InternetPrice />
                        )}

                      <DiscountBadge
                        state={
                          selectedVariant
                            ? selectedVariant?.badges[0]?.value?.length > 0
                              && selectedVariant.badges[0]?.type === 'DISCOUNT'
                            : props.badges[0]?.value?.length > 0
                              && props.badges[0]?.type === 'DISCOUNT'
                        }
                        label={
                          selectedVariant
                            ? selectedVariant?.badges[0]?.value
                            : props.badges[0]?.value
                        }
                      />
                    </div>

                    <div className={style.productVertical__prices}>
                      <ProductPrice
                        content={
                          selectedVariant
                            ? selectedVariant?.prices[0]?.price
                            : props?.prices[0]?.price
                        }
                      />
                      {selectedVariant
                        ? oldPriceVariant && (
                        <ProductNormalPrice content={oldPrice} />
                        )
                        : oldPrice && <ProductNormalPrice content={oldPrice} />}
                      {props?.priceM2 && (
                        <ProductPrice
                          content={props?.priceM2}
                          className={style.priceM2}
                          caja
                        />
                      )}
                    </div>
                  </div>

                  <div className={style.productVertical__iconButton}>
                    <section
                      className={style.productVertical__iconButton__section}
                    >
                      <IconButton
                        size="big"
                        icon={isAddToCartLoading ? <Loader /> : <AddCar />}
                        color="#dd2100"
                        onClick={
                          isAddToCartLoading
                            ? null
                            : () => {
                              console.log('agrega al carro');
                              console.log(selectedVariant?.targetId);

                              props.onAddCartClick(props.productId);
                            }
                        }
                      />
                    </section>
                  </div>
                </section>
                <Button
                  color="secondary"
                  variant="text"
                  onClick={onClickSaveList}
                >
                  Guardar en Mi lista
                </Button>
              </div>

              {/* Badge */}
              <div className={style.productVertical__BadgeDesktop}>
                <div className={style.productVertical__priceBadge}>
                  {selectedVariant
                    ? selectedVariant.prices[0]?.type === 'CMR' && <CmrBadge />
                    : props.prices[0]?.type === 'CMR' && <CmrBadge />}
                  {selectedVariant
                    ? selectedVariant.prices[0]?.type === 'INTERNET' && (
                    <InternetPrice />
                    )
                    : props.prices[0]?.type === 'INTERNET' && <InternetPrice />}
                  <DiscountBadge
                    state={
                      selectedVariant
                        ? selectedVariant?.badges[0]?.value?.length > 0
                          && selectedVariant.badges[0]?.type === 'DISCOUNT'
                        : props.badges[0]?.value?.length > 0
                          && props.badges[0]?.type === 'DISCOUNT'
                    }
                    label={
                      selectedVariant
                        ? selectedVariant?.badges[0]?.value
                        : props.badges[0]?.value
                    }
                  />
                </div>
                <div className={style.productVertical__prices}>
                  <ProductPrice
                    content={
                      selectedVariant
                        ? selectedVariant?.prices[0]?.price
                        : props?.prices[0]?.price
                    }
                  />
                  {selectedVariant
                    ? oldPriceVariant && (
                    <ProductNormalPrice content={oldPrice} />
                    )
                    : oldPrice && <ProductNormalPrice content={oldPrice} />}
                  {props?.priceM2 && (
                    <ProductPrice
                      content={props?.priceM2}
                      className={style.priceM2}
                      caja
                    />
                  )}
                </div>
                {props.timerTime && <Timer timerTime={props.timerTime} />}
              </div>
            </section>
          </div>

          <div className={style.productVertical__available}>
            {props.availability?.clickAndCollect && (
              <div className={style.productVertical__available_item}>
                <Success />
                <span>Disponible para despacho</span>
              </div>
            )}
            {props.availability?.clickAndCollect && (
              <div className={style.productVertical__available_item}>
                <Success />
                <span>Disponible para retiro</span>
              </div>
            )}
          </div>

          {hasMultiPurposeIcon && (
            <div className={style.productVertical__multipurposeBadge}>
              <img
                src={props.multiPurposeIcon?.iconUrl}
                alt="badge multiproposito"
              />
              <Typography variant="kappa14">
                {props?.multiPurposeIcon?.description}
              </Typography>
            </div>
          )}

          <section>
            <div className={style.productVertical__button}>
              <Button
                color={isAddToCartLoading ? 'secondary' : 'primary'}
                variant={isAddToCartLoading ? 'loading' : 'outlined'}
                onClick={
                  isAddToCartLoading
                    ? null
                    : () => {
                      console.log('agrega al carro');
                      console.log(selectedVariant?.targetId);

                      props.onAddCartClick(
                        selectedVariant
                          ? selectedVariant?.targetId
                          : props.productId,
                      );
                    }
                }
              >
                {isAddToCartLoading ? (
                  <>
                    <Loader />
                    {' '}
                    Agregando
                    {' '}
                  </>
                ) : (
                  'Agregar al carro'
                )}
              </Button>
            </div>
            <div className={style.productVertical__lista}>
              <Button
                color="secondary"
                variant="text"
                onClick={onClickSaveList}
              >
                Guardar en Mi lista
              </Button>
            </div>
          </section>
        </div>
      )}
    </BadgesContainer>
  );
};

ProductCardVertical.propTypes = {
  isAddToCartLoading: PropTypes.bool,
  name: PropTypes.string,
  skuId: PropTypes.string,
  brand: PropTypes.string,
  priceM2: PropTypes.string,
  rating: PropTypes.number,
  prices: PropTypes.arrayOf(PropTypes.shape({})),
  availability: PropTypes.shape({}),
  badges: PropTypes.arrayOf(PropTypes.shape({})),
  variants: PropTypes.arrayOf(PropTypes.shape({})),
  multiPurposeIcon: PropTypes.shape({}),
  totalReviews: PropTypes.number,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  normalPrice: PropTypes.string,
  timerTime: PropTypes.number,
  eventBadge: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  bankBadge: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  garantieBadge: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  discountBadge: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  onCheckboxClick: PropTypes.func,
  onAddCartClick: PropTypes.func,
  onClickSaveList: PropTypes.func,
  isChecked: PropTypes.bool,
};

export default ProductCardVertical;
