/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Carousel from 'react-simply-carousel';
import { useMediaQuery } from 'react-responsive';
import CategoryIcon from '../../atoms/CategoryIcon/CategoryIcon';
import { ReactComponent as ArrowLeft } from '../../../../assets/svg/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/ArrowRight.svg';
import style from './CategoriesMenu.module.scss';
import { arrayInArray } from '../../../../utils';
import Typography from '../../atoms/Typography/Typography';
import Container from '../../../layout/Container/Container';

const CategoriesMenu = ({
  state,
  categories,
  carouselRows,
  title,
  subtitle,
  titlePosition,
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const [currentSlider, setCurrentSlider] = useState(0);

  const categoriesOneRowInArray = arrayInArray(categories, 8);
  const categoriesTwoRowInArray = arrayInArray(categories, 16);

  const hasMoreThanACarousel = () => {
    if (carouselRows === '1') {
      return categoriesOneRowInArray?.length > 1;
    }
    if (carouselRows === '2') {
      return categoriesTwoRowInArray?.length > 1;
    }
    return null;
  };

  const updateCurrentSlide = (index) => {
    if (currentSlider !== index) {
      setCurrentSlider(index);
    }
  };

  const handleClick = (ev, marcacion) => {
    if (!marcacion) {
      navigate(`/categories/${ev.target.value}`);
    } else {
      navigate(`/categories/${ev.target.value}&${marcacion}`);
    }
  };

  const IsMobile = useMediaQuery({
    query: '(max-width: 750px)',
  });

  let contentOfTheCarousel;
  if (carouselRows === '1') {
    contentOfTheCarousel = categories?.map((item, index) => (
      <div key={index} className={style.categories_carousel__content_carousel}>
        <CategoryIcon
          showStatus
          catId={item.id}
          titlePosition={titlePosition}
          key={index}
          imgBrand={item.image}
          altbrand={item.name}
          onClick={(event) => {
            handleClick(event, item?.marcacion);
          }}
        />
      </div>
    ));
  }
  if (carouselRows === '2') {
    contentOfTheCarousel = arrayInArray(categories, 2)?.map(
      (twoBrands, index) => (
        <div
          key={index}
          style={{
            width: '200px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            paddingRight: '16px',
          }}
        >
          {twoBrands.map((brand, indexBrand) => (
            <CategoryIcon
              key={`${index}${indexBrand}`}
              catId={`${brand.id}`}
              imgBrand={brand.image}
              altbrand={brand.name}
              titlePosition={titlePosition}
              onClick={(event) => {
                handleClick(event, brand?.marcacion);
              }}
              marcacion={brand.marcacion}
            />
          ))}
        </div>
      ),
    );
  }

  useEffect(() => {
    updateCurrentSlide(0);
    setCurrentSlider(currentSlider + 1);
    setCurrentSlider(currentSlider - 1);
  }, [categories]);

  const renderCarousel = () => (
    <Carousel
      centerMode
      disableSwipeByMouse
      activeSlideIndex={currentSlider}
      onRequestChange={setCurrentSlider}
      itemsToShow={3}
      itemsToScroll={1}
      infinite={false}
      forwardBtnProps={{
        children: <ArrowRight />,
        className: style.nextButtonBra,
      }}
      backwardBtnProps={{
        children: <ArrowLeft />,
        className: style.prevButtonBra,
      }}
      responsiveProps={[
        {
          itemsToShow: 4,
          itemsToScroll: 1,
          minWidth: 700,
        },
        {
          itemsToShow: 5,
          itemsToScroll: 1,
          minWidth: 900,
        },
        {
          itemsToShow: 6,
          itemsToScroll: 1,
          minWidth: 1220,
        },
      ]}
      speed={400}
      easing="linear"
    >
      {contentOfTheCarousel}
    </Carousel>
  );

  if (!state) {
    return null;
  }
  if (IsMobile) {
    return (
      <div className={style.categories_carousel}>
        <Container>
          {title?.value.length > 0 && (
            <div
              className={style.categories_carousel__header}
            >
              <header
                style={{ color: props.title?.color }}
              >
                <Typography
                  variant={title?.variant}
                  style={{
                    fontWeight: title?.strong ? '700' : '400',
                    marginBottom: '20px',
                  }}
                >
                  {title?.value}
                </Typography>
                {subtitle && (
                  <Typography
                    variant={subtitle?.variant}
                    style={{
                      fontWeight: subtitle?.strong ? '700' : '400',
                      marginBottom: '30px',
                    }}
                  >
                    {subtitle?.value}
                  </Typography>
                )}
              </header>
            </div>
          )}
          <div className={style.categories_carousel__content__mobile}>
            {categories?.map((item, index) => (
              <CategoryIcon
                catId={item.id}
                titlePosition={titlePosition}
                key={index}
                imgBrand={item.image}
                altbrand={item.name}
                onClick={handleClick}
              />
            ))}
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div className={style.categories_carousel}>
      <Container>
        {title?.value.length > 0 && (
          <div className={style.categories_carousel__header}>
            <header>
              <Typography
                variant={title?.variant}
                style={{
                  color: title?.color,
                  fontWeight: title?.strong ? '700' : '400',
                  marginBottom: '20px',
                }}
              >
                {title?.value}
              </Typography>
              {subtitle && (
                <Typography
                  variant={subtitle?.variant}
                  style={{
                    fontWeight: subtitle?.strong ? '700' : '400',
                  }}
                >
                  {subtitle?.value}
                </Typography>
              )}
            </header>
          </div>
        )}
        <div className={style.categories_carousel__content}>
          {renderCarousel()}
        </div>
      </Container>
    </div>
  );
};

CategoriesMenu.propTypes = {
  /**
   * State "true" to turn on the component and "false" to turn off the component
   */
  state: PropTypes.bool,
  /**
   * Brands list of carousel
   */
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  /**
   * Number of rows to show in the carousel
   */
  carouselRows: PropTypes.oneOf(['1', '2']),
  /**
   * Section title
   */
  title: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
    color: PropTypes.string,
  }),
  subtitle: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
    color: PropTypes.string,
  }),
  /**
   * Title position in the carousel section
   */
  titlePosition: PropTypes.oneOf(['top', 'bottom']),
};

export default CategoriesMenu;
